
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import type { GetStaticProps, NextPage } from 'next';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
const NotFoundPage: NextPage = function () {
  return (
    <>
      <NextSeo noindex={true} nofollow={true} />
      <div className='p-container flex flex-col items-center bg-white'>
        <div className='flex h-screen w-screen items-center justify-center'>
          <div className='px-4'>
            <div className='lg:flex lg:gap-4'>
              <div className='flex flex-col items-center justify-center md:py-24 lg:py-32'>
                <h1 className='text-9xl font-bold text-primary'>404</h1>
                <p className='mb-2 text-center text-2xl font-bold text-gray-800 md:text-3xl'>
                  <span className='text-orange-600'>Oops!</span> Page not found
                </p>
                <p className='mb-8 text-center text-gray-500 md:text-lg'>
                  The page you’re looking for doesn’t exist.
                </p>
                <Link href='/'>
                  <a className='bg-blue-100 px-6 py-2 text-sm font-semibold text-blue-800'>
                    Go home
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

 const _getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};

export default NotFoundPage;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  